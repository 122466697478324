import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Chapo from "../components/Chapo"

export const pageQuery = graphql`
	query {
		settings: prismicSiteSettings(lang: { eq: "fr-fr" }) {
			...settings
		}
	}
`

const Page404 = (props) => {
	const { settings } = props.data
	// console.log(settings)
	return (
		<div className="page-404 vh100">
			<Helmet>
				<body className={"template-404"} />
			</Helmet>
			<div
				className="cover psf w100 h100"
				style={{ backgroundImage: `url(${settings.data.image_404.url})` }}
			></div>
			<Chapo title={""} texte={settings.data.message_404.html} />
		</div>
	)
}

export default Page404
